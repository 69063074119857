class Helper {
    //去左右空格;
    trim(s) {
        return s.replace(/(^\s*)|(\s*$)/g, "");
    }

    // 函数防抖
    debounce(fn, delay) {
        let timeout = null;
        return function () {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                fn.call(this, arguments);
            }, delay);
        };
    }

    // 函数节流
    throttle(fn, delay) {
        var prev = Date.now();
        return function () {
            var context = this;
            var args = arguments;
            var now = Date.now();
            if (now - prev >= delay) {
                fn.apply(context, args);
                prev = Date.now();
            }
        }
    }

    firstCapitalLetter(str) {
        return str.charAt(0).toUpperCase() + str.substring(1);
    }
    deepClone(obj, cache = []) {
        // 判断原对象是否存在
        // if (obj) {
        //     var newObj = JSON.parse(JSON.stringify(obj));
        //     return newObj;
        // }
        // just return if obj is immutable value
        if (obj === null || typeof obj !== 'object') {
            return obj
        }
        const hit = cache.find(c => c.original === obj)
        if (hit) {
            return hit.copy
        }

        const copy = Array.isArray(obj) ? [] : {}
        cache.push({
            original: obj,
            copy
        })

        Object.keys(obj).forEach(key => {
            copy[key] = helper.deepClone(obj[key], cache)
        })

        return copy
    }
}

const helper = new Helper();

export default helper;


